import React, { Component } from 'react';
import CriteriumField from './CriteriumField';

class FontField extends Component {
  render() {
    let font = 'GrueneType';

    if (typeof this.props.data === 'undefined') {
      return <div />;
    }

    if (this.props.data.value) {
      return <CriteriumField keyProp='font' type='positive' title={`Die Site verwendet die Schriftart ${font}`} />;
    }

    return <CriteriumField keyProp='font' type='negative' title={`Die Site sollte die Schriftart ${font} verwenden`}>
        <p>Die Schriftart GrueneType ist ein markanter Bestandteil der
          Corporate-Design-Richtlinien von BÜNDNIS 90/DIE GRÜNEN. Die Verwendung der Schrift hilft dabei, den Absender
          kenntlich zu machen, so wie es auch der Einsatz der richtigen Farben und die Verwendung des Logos tun.</p>
        <p>Die empfohlenen Schriften stehen unter{' '}
          <a href='https://gruene.de/schriften' target='_blank'
          rel="noopener noreferrer">gruene.de/schriften</a> für die
          Verwendung auf Webseiten zur Verfügung.</p>
      </CriteriumField>;
  }
}

export default FontField;
